export default {
	room: {
		room1: 'About Us',
		room2: 'Add some materials to the home',
		room3: 'FASTER! CHEAPER! BETTER!',
		room4: 'Build your dream new home with your own hands',
		room5: 'Build My Dream Home',
		room6: 'I just want to Renovate',
		room7: 'Learn more',
		room8: 'Let AI help me Renovate!',
		room9: 'Contact Designer',
		room10: 'address',
		room11: 'Please enter your name',
		room12: 'Please enter your phone number',
		room13: 'Select Date',
		room14: 'Submit',
		room15: 'Contact Us',
		room16: 'FASTER! CHEAPER!',
		room17: 'BETTER!',
		room18: 'Let AI help me',
		room19: 'Decorate',
		room20: 'Submitted successfully',
		room21: 'Select',
		room22: 'Return',
		room23: 'Swipe up and down',
		room24: 'Unit Selection',
		room25: '3D Rendering & Floor Plan',
		room26: 'View Next House',
		room27: 'Swipe up and down',
		room28: 'Swipe left and right',
		room29: 'Confirm',
		room30: 'Total',
		room31: 'House Type',
		room32: 'Selection',
		room33: 'View Next',
		room34: 'Swipe left and right',
		room35: 'Designer Recommendation (FREE)',
		room36: 'Contact now for FREE gift',
		room37: 'Design Specifications',
		room38: 'View Rooms',
		room39: 'Decoration progress',
		room40: 'View Selected Configuration',
		room41: 'Submit',
		room42: 'Next',
		room43: 'Error submitting',
		room44: 'Your Selection:',
		room45: 'The configuration you have selected',
		room46: 'Current configuration budget cost',
		room47: 'Expected duration of current configuration (days)',
		room48: 'SAVE',
		room49: 'Send to Email',
		room50: 'Please enter your email address',
		room51: 'Please name it',
		room52: 'save',
		room53: 'sending',
		room54: 'Successfully sent',
		room55: 'Successfully saved',
		room56: 'login',
		room57: 'Or',
		room58: 'register',
		room59: 'Retract',
		room60: 'Please enter the password',
		room61: 'Please enter the verification code',
		room62: 'Send code',
		room63: 'Please enter your phone number',
		room64: 'Registration Agreement',
		room65: 'Login',
		room66: 'My Save Scheme',
		room67: 'RAAS 99Living',
		room68: 'Login succeeded',
		room69: 'Fail',
		room70: 'successful',
		room71: 'Registration failed, please check the registration information',
		room72: 'Choose',
		room73: 'Type',
		room74: 'Code',
		room75: 'Material',
		room76: 'Other materials',
		room77: 'Size',
		room78: 'Description',
		room79: 'Avatar',
		room80: 'Email',
		room81: 'Moblie',
		room82: 'Tips',
		room83: 'Jump to the previous step, the parameters you have currently selected will be cleared!',
		room84: 'Cancel',
		room85: 'Do you want to delete this scheme',
		room86: 'Please check the registration agreement',
		room87:'Please enter a name',
		room88:'Profile',
		room89:'Currently, there are no options for furniture. Please check again.',
		room90:'Num',
		room91:'Unit price',
		room92:'Configuration cannot be empty',
		room93:'Rich house style and layout selections',
		room94:"There's definitely one that you'll like!",
		room95:'Currently, Total',
		room96:'Type of house',
		room97:'New designs coming soon!',
		room98:'Continue',
		room99:'Bringing together the most mainstream decoration styles in the world',
		room100:'Each one is carefully selected for you',
		room101:'Each style comes in both dark and light theme options',
		room102:'Expand',
		room103:'Color',
		room104:'Do you have any design proposals that have not been submitted? Do you want to continue submitting them'
	}
}