import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n';
import fontCN from '@/assets/language/zh.js'
import fontEN from '@/assets/language/en.js'
import fontTH from '@/assets/language/th.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import https from './api/api.js'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.prototype.http = https
Vue.use(VueI18n);
Vue.use(ElementUI);
async function lang(){
	const res = await https.fetchPost('/api/Content/config')
	if(localStorage.getItem('language_key')){
		return localStorage.getItem('language_key')
	}else{
		localStorage.setItem('language_key',res.data.default_lan)
		return res.data.default_lan
	}
	
}

const i18n = new VueI18n({//设置默认语言为简体
	locale: await lang(),
	messages: {
		'zh': fontCN,//简体
		'en':fontEN,
		'th':fontTH
	}
})
new Vue({
  router,
	i18n,
  render: h => h(App)
}).$mount('#app')
