import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: index
	},
	{
		path: '/houseType',
		name: 'houseType',
		component: () => import('../views/houseType.vue')
	},
	{
		path: '/houseItem',
		name: 'houseItem',
		component: () => import('../views/houseItem.vue')
	},
	{
		path: '/houseItem2',
		name: 'houseItem2',
		component: () => import('../views/houseItem2.vue')
	},
	{
		path: '/gear',
		name: 'gear',
		component: () => import('../views/gear.vue')
	},
	{
		path: '/inventory',
		name: 'inventory',
		component: () => import('../views/inventory.vue')
	},
	{
		path: '/demo',
		name: 'demo',
		component: () => import('../views/demo.vue')
	}, {
		path: '/houseOne',
		name: 'houseOne',
		component: () => import('../views/houseOne.vue')
	}, {
		path: '/houseTwo',
		name: 'houseTwo',
		component: () => import('../views/houseTwo.vue')
	}
]

const router = new VueRouter({
	 mode: 'history',
	  base: process.env.BASE_URL,

	routes
})

export default router