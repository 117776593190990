<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>
<script>
	window.addEventListener('scroll', function(ev) {
		document.body.toggleAttribute('scroll', true)
		this.timer && clearTimeout(this.timer)
		this.timer = setTimeout(() => {
			document.body.toggleAttribute('scroll')
		}, 500)
	})
	export default {
		name: "app",
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				})
			}
		}
	}
</script>
<style lang="less">
	body {
		margin: 0;
		overflow: overlay;
	}

	::-webkit-scrollbar {
		width: 0;
	}

	::-webkit-scrollbar {
		width: 0;
	}

	.u-flex {
		display: flex;
	}

	.u-flex-y-center {
		display: flex;
		align-items: center;
	}

	.u-flex-between {
		justify-content: space-between;
	}

	div {
		box-sizing: border-box;


	}

	@media screen and (min-width: 750px) {
		.pageWAP {
			width: 100%;
			display: none !important;
		}

		.app-obtick {
			width: 100vw;
			height: 100vh;
			position: fixed;
			z-index: 999;
			background-color: #00000080;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;

			.app-tips {
				width: 530px;
				background: #000000;
				padding: 40px 60px;

				.app-tit {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					margin-bottom: 40px;
				}

				.app-info {
					font-size: 18px;
					font-weight: 400;
					color: #FF3F3F;
					margin-bottom: 40px;
				}

				.app-btns {
					display: flex;
					justify-content: space-between;

					.aBtn1 {
						width: 195px;
						height: 44px;
						background: #333333;
						text-align: center;
						line-height: 44px;
						font-size: 12px;
						color: #fff;
						cursor: pointer;
					}
				}
			}
		}
	}

	@media screen and (max-width: 750px) {
		.pagePC {
			display: none !important;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .safari_only {
			bottom: 100px !important; 
		}

		.app-obtick {
			width: 100vw;
			height: 100vh;
			position: fixed;
			z-index: 99;
			background-color: #00000080;
			display: flex;
			align-items: flex-end;
			top: 0;

			.app-tips {
				width: 100%;
				height: 236px;
				padding: 40px 60px;

				.app-tit {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					margin-bottom: 40px;
				}

				.app-info {
					font-size: 18px;
					font-weight: 400;
					color: #FF3F3F;
					margin-bottom: 40px;
				}

				.app-btns {
					display: flex;
					justify-content: space-between;

					.aBtn1 {
						width: 48%;
						height: 36px;
						background: #333333;
						text-align: center;
						line-height: 36px;
						font-size: 12px;
						color: #fff;
						cursor: pointer;
					}
				}
			}

			.app-Card {
				width: 100%;
				overflow-y: auto;
				background: #000000;
				padding: 32px;
				box-sizing: border-box;
				position: relative;

				.app-tit {
					font-size: 22px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 22px;
				}

				.close {
					width: 22px;
					height: 22px;
					top: 25px;
					right: 25px;
					position: absolute;
					cursor: pointer;
				}

				.inp {
					width: 100%;
					border-bottom: 1px solid #4c4c4c;
					padding: 16px 0;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					margin-top: 16px;

					img {
						width: 22px;
						height: 22px;
					}

					input {
						padding: 0 12px;
						border: none;
						outline: none;
						font-size: 14px;
						color: #fff;
						flex: 1;
						width: 100%;
						background-color: #000000;
					}
				}


			}
		}
	}
</style>