export default {
	room:{
		room1:'详细了解99living',
		room2:'给家添点料',
		room3:'更快，更好，更便宜',
		room4:'亲手打造你的梦幻新家',
		room5:'DIY我的梦幻新家',
		room6:'我只需要装修',
		room7:'了解更多',
		room8:'让Ai帮我来装修',
		room9:'联系设计师',
		room10:'地址',
		room11:'请输入姓名',
		room12:'请输入电话',
		room13:'选择日期',
		room14:'提交',
		room15:'联系我们',
		room16:'更快，更好，',
		room17:'更便宜',
		room18:'让Ai帮我',
		room19:'来装修',
		room20:'提交成功',
		room21:'立即选择',
		room22:'返回',
		room23:'可上下滑动切换房型',
		room24:'户型选择',
		room25:'外观户型一图纸详解',
		room26:'查看下一个户型',
		room27:'可上下滑动切换户型',
		room28:'可左右滑动切换图纸',
		room29:'确定',
		room30:'共',
		room31:'款户型',
		room32:'风格选择',
		room33:'查看下一个风格',
		room34:'可左右滑动切换当前色系更多效果图',
		room35:'设计师推荐装修(免费)',
		room36:'现在联系，免费送家电大礼包',
		room37:'配置装修',
		room38:'查看房间',
		room39:'装修进度',
		room40:'查看已选配置',
		room41:'提交设计',
		room42:'下一个',
		room43:'方案提交错误',
		room44:'清单',
		room45:'您选择的配置',
		room46:'当前配置预算费用',
		room47:'当前配置预计工期(天)',
		room48:'保存方案',
		room49:'发送邮箱',
		room50:'请输入邮箱账号',
		room51:'请命名',
		room52:'保存',
		room53:'发送',
		room54:'发送成功',
		room55:'保存成功',
		room56:'登录',
		room57:'或',
		room58:'注册',
		room59:'收起',
		room60:'请输入密码',
		room61:'请输入验证码',
		room62:'发送验证码',
		room63:'请输入手机号',
		room64:'注册协议',
		room65:'已有账号直接登录',
		room66:'我的保存方案',
		room67:'家装网',
		room68:'登录成功',
		room69:'发送失败',
		room70:'注册成功',
		room71:'注册失败，请检查注册资料',
		room72:'选择',
		room73:'类型',
		room74:'编码',
		room75:'材料',
		room76:'其它材料',
		room77:'尺寸',
		room78:'详细说明',
		room79:'头像',
		room80:'邮箱',
		room81:'手机号',
		room82:'提示',
		room83:'即将跳转至上一步,您当前选择的参数将清除!',
		room84:'取消',
		room85:'是否删除该方案',
		room86:'请勾选注册协议',
		room87:'请输入名称',
		room88:'简介',
		room89:'当前家具暂无选项请查看下一个',
		room90:'数量',
		room91:'单价',
		room92:'配置不能为空',
		room93:'丰富的房屋外观与户型选择',
		room94:'总有一款适合您',
		room95:'目前已有',
		room96:'款房型',
		room97:'不断增加中',
		room98:'继续',
		room99:'集合了世界上最主流的装修风格',
		room100:'每一款都为您精挑细选',
		room101:'每款风格均有深色系与浅色系可选',
		room102:'展开',
		room103:'颜色',
		room104:'您有未提交的设计方案，是否继续提交'
	}
}