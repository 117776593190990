export default {
	room:{
		room1:'เกี่ยวกับเรา',
		room2:'เลือกวัสดุตกแต่งให้กับบ้านของคุณ',
		room3:'ถูกกว่า! เร็วกว่า! ดีกว่า!',
		room4:'สร้างบ้านในฝันด้วยตนเอง',
		room5:'DIY บ้านในแบบที่คุณวาดฝัน',
		room6:'แค่ทดลองตกแต่ง',
		room7:'เรียนรู้เพิ่มเติม',
		room8:'ให้ AI ช่วยตกแต่งให้คุณ',
		room9:'ติดต่อดีไซเนอร์',
		room10:'ที่อยู่',
		room11:'ชื่อผู้ติดต่อ',
		room12:'เบอร์ผู้ติดต่อ',
		room13:'เลือกวัน',
		room14:'ส่งข้อมูล',
		room15:'ติดต่อเรา',
		room16:'ถูกกว่า! เร็วกว่า!',
		room17:'ดีกว่า!',
		room18:'ให้ช่วยคุณ',
		room19:'ตกแต่ง',
		room20:'ส่งข้อมูล สำเร็จ',
		room21:'เลือก',
		room22:'ย้อนกลับ',
		room23:'เลื่อนขึ้น - เลื่อนลง',
		room24:'เลือกประเภท',
		room25:'ทัศนียภาพจำลองและแบบแปลน',
		room26:'ดูแบบบ้านถัดไป',
		room27:'เลื่อนขึ้น - ลง',
		room28:'เลื่อนซ้าย - ขวา',
		room29:'ยืนยัน',
		room30:'รวมทั้งหมด',
		room31:'ประเภทบ้าน',
		room32:'รูปแบบที่เลือก',
		room33:'ดูรูปแบบถัดไป',
		room34:'เลื่อนซ้าย - ขวา',
		room35:'ขอรับการแนะนำโดยดีไซเนอร์ (ฟรี)',
		room36:'ติดต่อเราเพื่อรับของสมนาคณฟรี!',
		room37:'รายละเอียดแบบ',
		room38:'ดูห้องต่างๆ',
		room39:'ความคืบหน้าการตกแต่ง',
		room40:'ดูการตกแต่งที่เลือกไว้',
		room41:'ยืนยันส่ง',
		room42:'ต่อไป',
		room43:'เกิดข้อผิดพลาดในการส่งข้อมูล',
		room44:'รายการที่คุณเลือกไว้',
		room45:'ดูรูปการตกแต่งที่เลือกไว้',
		room46:'ค่าใช้จ่ายโดยประมาณจากที่เลือกไว้',
		room47:'จำนวนวันที่คาดการณ์ว่าจะใช้ในการก่อสร้างเสร็จ (วัน)',
		room48:'บันทึก',
		room49:'ส่ง Email',
		room50:'กรุณากรอก Email ของคุณ',
		room51:'กรุณาตั้งชื่อ',
		room52:'บันทึก',
		room53:'กำลังส่ง',
		room54:'ส่งข้อมูลสำเร็จ',
		room55:'บันทึกข้อมูลสำเร็จ',
		room56:'เข้าสู่ระบบ',
		room57:'หรือ',
		room58:'ลงทะเบียน',
		room59:'ยกเลิก',
		room60:'กรุณากรอกรหัสผ่าน',
		room61:'กรุณากรอกรหัสยืนยันที่คุณได้รับ',
		room62:'ส่งรหัสยืนยัน',
		room63:'กรุณากรอกหมายเลขโทรศัพท์',
		room64:'ข้อตกลงการลงทะเบียน',
		room65:'เข้าสู่ระบบ',
		room66:'โครงการที่บันทึกของฉัน',
		room67:'ระบบ RAAS 99Living',
		room68:'เข้าสู่ระบบสำเร็จ',
		room69:'ทำรายการไม่สำเร็จ',
		room70:'เข้าสู่ระบบสำเร็จ',
		room71:'การลงทะเบียนล้มเหลว โปรดตรวจสอบข้อมูลของคุณ',
		room72:'เลือก',
		room73:'รูปแบบ',
		room74:'รหัส',
		room75:'วัสดุ',
		room76:'วัสดุอื่น ๆ',
		room77:'ขนาด',
		room78:'รายละเอียด',
		room79:'รูปโปรไฟล์',
		room80:'Email',
		room81:'หมายเลขโทรศัพท์มือถือ',
		room82:'เคล็ดลับ',
		room83:'ย้อนกลับ รายการที่เลือกไว้จะถูกลบออก!',
		room84:'ยกเลิก',
		room85:'คุณต้องการลบรูปแบบนี้หรือไม่',
		room86:'กรุณาตรวจสอบข้อตกลงการลงทะเบียน',
		room87:'กรุณากรอกชื่อ',
		room88:'โปรไฟล์',
		room89:'ปัจจุบันไม่มีตัวเลือกสำหรับเฟอร์นิเจอร์ กดดูถัดไป',
		room90:'จำนวน',
		room91:'ราคา',
		room92:'การกำหนดค่าต้องไม่ว่างเปล่า',
		room93:'หลากหลายรูปแบบบ้านให้คุณเลือก',
		room94:'มีแบบบ้านที่คุณชอบอย่างแน่นอน!',
		room95:'ปัจจุบันมีทั้งหมด',
		room96:'รูปแบบบ้าน',
		room97:'โปรดติดตามแบบใหม่ๆจากเรา!',
		room98:'ดำเนินการต่อ',
		room99:'รวบรวมสไตล์การตกแต่งชั้นนำของโลก',
		room100:'แต่ละสไตล์ได้รับการคัดสรรมาอย่างดีเพื่อคุณ',
		room101:'มีให้เลือกทั้งโทนมืดและโทนสว่าง',
		room102:'ขยาย',
		room103:'สี',
		room104:'คุณมีแผนการออกแบบที่ยังไม่ได้ส่งหรือไม่ว่าจะดำเนินการต่อ'
	}
}