<template>
	<div>
		<navBar></navBar>
		<div class="pagePC">
			<div class="swiper-container yang" style="height: 100vh;position: absolute;top: 0;z-index: -1;">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="item in banner">
						<div class="imgItem" style="width: 100vw;height: 100vh;">
							<img :src="item.image" class="back" />
						</div>
					</div>

				</div>
			</div>
			<div class="swiper-container page" style="height: 100vh;">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="homePage">
							<video autoplay="autoplay" muted="muted" loop class="playVideo" id="videoFirst"
								style="width: 100vw; height: 100vh; object-fit: fill;position: absolute;top: 0;left: 0;z-index: -1;"
								:poster="video1">
								<source :src="video1" type="video/mp4">
							</video>
							<div style="position:relative;width: 100vw;height: 100vh;">
								<div class="back">
									<div class="imgItem">
										<div class="title">{{$t('room.room3')}}</div>
										<div class="subTitle">{{$t('room.room4')}}</div>
										<img class="right" src="../assets/qu.png" @click="toHose" />
										<div class="btns">
											<div class="btn" @click="toHose">
												<div>{{$t('room.room5')}}</div>
												<img src="../assets/mx.png" />
											</div>
											<div class="btn" @click="toNext">
												<div>{{$t('room.room6')}}</div>
												<img src="../assets/cd.png" />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<!-- 	<div class="swiper-slide">
						<div class="indexPc"  style="background-color: #00000050;">
							
							<div style="position:relative;width: 100vw;height: 100vh;">
								<img src="../assets/downB.png" class="down" @click="show=true" />
								<div class="more" @click="toNext">
									<div class="name">{{$t('room.room7')}}</div>
									<img src="../assets/rightMore.png" class="mImg" />
								</div>
							</div>
						</div>
					</div> -->
					<div class="swiper-slide">
						<!--了解更多-->
						<div class="callPage" style="background-color: #00000050;">
							<video autoplay="autoplay" muted="muted" loop class="playVideo" id="videoFirst2"
								style="width: 100vw; height: 100vh; object-fit: fill;position: absolute;top: 0;left: 0;z-index: -1;"
								:poster="video2">
								<source :src="video2" type="video/mp4">
							</video>
							<div class="tit">{{$t('room.room8')}}</div>
							<div class="more" @click="showTips=true">
								<div class="name">{{$t('room.room7')}}</div>
								<img src="../assets/rightMore.png" class="mImg" />
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<!--联系我们-->
						<div class="morePage">
							<div class="moreBox">
								<div class="centerBox">
									<div>
										<div class="title">{{$t('room.room9')}}</div>
										<div class="sub">{{baseInfo.mobile}}</div>
										<div class="sub" style="margin-bottom: 150px;">{{$t('room.room10')}}：{{baseInfo.address}}</div>
									</div>
									<div class="sendBox">
										<div class="item">
											<img src="../assets/cell1.png" />
											<input :placeholder="$t('room.room11')" v-model="name" />
										</div>
										<div class="item">
											<img src="../assets/cell2.png" />
											<input :placeholder="$t('room.room12')" v-model="mobile" />
										</div>
										<div class="item">
											<img src="../assets/cell3.png" />
											<el-date-picker style="background-color: #000000;" v-model="yu_time" value-format="yyyy-MM-dd"
												format="yyyy-MM-dd" type="date" :placeholder="$t('room.room13')">
											</el-date-picker>
										</div>
										<div class="btn" @click="send">{{$t('room.room14')}}</div>
									</div>
								</div>
								<div class="footer">
									<img src="../assets/up.png" class="down" @click="toNext3()" />
									<div class="info">
										<div>
											<div class="title">follow us</div>
											<div class="imgList">
												<div style="display: flex;align-items: center;margin-right: 30px;" v-for="(item,index) in sfot">
													<a :href="item.url" target="_blank"><img :src="item.image" class="imgs" />
													</a>
												</div>
											</div>
										</div>
										<div style="text-align: right;">
											<div class="cell">{{$t('room.room15')}}</div>
											<div class="phone">{{baseInfo.mobile}}</div>
											<div class="cell">{{$t('room.room10')}}：{{baseInfo.address}}</div>
										</div>
									</div>
									<div class="copy">{{baseInfo.beian}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-obtick" v-if="showTips">
				<div class="app-tips" style="height: auto;width: 60vw;">
					<div class="app-info" style="color: #fff;height:500px;overflow: auto;" v-html="aiInfo">
					</div>
					<div class="app-btns" style="justify-content: center;">
						<div class="aBtn1" @click="showTips=false">{{$t('room.room29')}}</div>
					</div>
				</div>

			</div>
		</div>
		<div class="pageWAP">
			<!-- 			<div class="swiper-container yang" style="height: 100vh;position: absolute;top: 0;z-index: -1;">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="item in banner">
						<img :src="item.image" class="back" style="width: 100vw;height: 100vh;" />
					</div>

				</div>
			</div> -->
			<div class="swiper-container page" style="height: 100vh;">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="imgItem">
							<video autoplay="autoplay" muted="muted" loop class="playVideo" id="videoFirst3"
								style="width: 100vw; height: 100vh; object-fit: cover;position: absolute;top: 0;left: 0;z-index: -1;"
								:poster="video3">
								<source :src="video3" type="video/mp4">
							</video>
							<!-- <video src="../assets/wap1.mp4" style="width: 100vw;height: 100vh;"></video> -->
							<div class="title">{{$t('room.room16')}}<br />{{$t('room.room17')}}</div>
							<div class="subTitle">{{$t('room.room4')}}</div>
							<img class="right" @click="toHose" src="../assets/qu.png" />
							<div class="btns">
								<div class="btn" @click="toHose">
									<div>{{$t('room.room5')}}</div>
									<img src="../assets/mx.png" />
								</div>
								<div class="btn" @click="toNext">
									<div>{{$t('room.room6')}}</div>
									<img src="../assets/cd.png" />
								</div>
							</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="imgItem">
							<video autoplay="autoplay" muted="muted" loop class="playVideo" id="videoFirst4"
								style="width: 100vw; height: 100vh; object-fit: cover;position: absolute;top: 0;left: 0;z-index: -1;"
								:poster="video4">
								<source :src="video4" type="video/mp4">
							</video>
							<div class="title">{{$t('room.room18')}}<br />{{$t('room.room19')}}</div>
							<div class="toMore" @click="showTips=true">
								<div>{{$t('room.room7')}}</div>
								<img src="../assets/rightM.png" />
							</div>

						</div>
					</div>
					<div class="swiper-slide">
						<div class="imgItem" style="background: linear-gradient(to bottom, #33333399, #000000);">
							<div class="cellMe">
								<div class="Ctitle">{{$t('room.room9')}}</div>
								<div class="tInfo" style="margin-bottom: 10px;">{{baseInfo.mobile}}</div>
								<div class="tInfo" style="margin-bottom: 5vh;">{{$t('room.room10')}}: {{baseInfo.address}}</div>
								<div class="inp">
									<img src="../assets/cell1.png" />
									<input :placeholder="$t('room.room11')" v-model="name" />
								</div>
								<div class="inp">
									<img src="../assets/cell2.png" v-model="mobile" />
									<input :placeholder="$t('room.room12')" />
								</div>
								<div class="inp">
									<img src="../assets/cell3.png" />
									<el-date-picker style="background-color: #000000;" v-model="yu_time" value-format="yyyy-MM-dd"
										format="yyyy-MM-dd" type="date" :placeholder="$t('room.room13')">
									</el-date-picker>
								</div>
								<div class="btn" @click="send">{{$t('room.room14')}}</div>
							</div>
							<div class="about">
								<div style="margin-bottom: 6px;">follow us</div>
								<div class="aBox">
									<div class="imgs">
										<div style="display: flex;align-items: center;margin-bottom: 5px;" v-for="(item,index) in sfot"><a
												:href="item.url" target="_blank"><img :src="item.image" />
											</a></div>
									</div>
									<div>
										<div>{{baseInfo.mobile}}</div>
										<div>{{$t('room.room10')}}: {{baseInfo.address}}</div>
									</div>
								</div>
								<div style="text-align: center;">{{baseInfo.beian}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-obtick" style="align-items: center;" v-if="showTips">
				<div class="app-tips" style="background-color: #000000;height: auto;">
					<div class="app-info" style="height: 40vh;overflow: auto;color:#fff" v-html="aiInfo">
					</div>
					<div class="app-btns" style="justify-content: center;">
						<div class="aBtn1" style="background-color: #666666;" @click="showTips=false">{{$t('room.room84')}}</div>
					</div>
				</div>

			</div>
			
		</div>
	</div>
</template>

<script>
	import navBar from '../components/navBar.vue'
	import Swiper from 'swiper/bundle'
	import 'swiper/swiper-bundle.css'
	export default {
		components: {
			navBar
		},
		data() {
			return {
				sfot: [],
				tabIndex: 0,
				banner: [],
				baseInfo: '',
				name: '',
				yu_time: '',
				mobile: '',
				swiper1: '',
				aiInfo: '',
				showTips: false,
				showTips2:false,
				pc_video: [],
				mb_video: [],
				myPlyer: null,
				myPlyer2: null,
				myPlyer3: null,
				myPlyer4: null,
				video1: '',
				video2: '',
				video3: '',
				video4: ''
			}
		},
		async mounted() {
			const res = await this.http.fetchPost('/api/Index/index')
			this.banner = res.data.banner
			this.myPlyer = document.getElementById("videoFirst");
			this.myPlyer2 = document.getElementById("videoFirst2");
			this.myPlyer3 = document.getElementById("videoFirst3");
			this.myPlyer4 = document.getElementById("videoFirst4");
			this.video1 = res.data.pc_video[0]
			this.video2 = res.data.pc_video[1]
			this.video3 = res.data.mb_video[0]
			this.video4 = res.data.mb_video[1]
			this.myPlyer.load();
			this.myPlyer2.load();
			this.myPlyer3.load();
			this.myPlyer4.load();
			this.sfot = res.data.sfot
			this.loadInfo()
			this.tabIndex = this.$route.query.index ? this.$route.query.index : 0
			this.swiper1 = new Swiper('.page', {
				direction: 'vertical',
				simulateTouch: false,
				mousewheel: true,
				initialSlide: this.tabIndex, // 默认选中项索引
			})
			new Swiper('.yang', {
				loop: true,
				autoplay: true,
				direction: "horizontal",
			})
		},
		async created() {
			let params = this.getRequestParams();
			if (params.token) {
				localStorage.setItem('token', params.token)
				const res = await this.http.fetchPost('/api/User/index')
				localStorage.setItem('userInfo', JSON.stringify(res.data))
				if (localStorage.getItem('sendid')) {
					this.showTips2=true
					this.$router.push({
						name: 'inventory',
						query: {
							id: localStorage.getItem('sendid'),
							type: 'add'
						}
					})
					
				}
			} else {
			
			
			}
			
		},
		methods: {
			getRequestParams() {
				let url = location.href;
				let requestParams = {};
				if (url.indexOf('?') !== -1) {
					let str = url.substr(url.indexOf('?') + 1); //截取?后面的内容作为字符串
					let strs = str.split('&'); //将字符串内容以&分隔为一个数组
					for (let i = 0; i < strs.length; i++) {
						requestParams[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
						// 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
					}
				}
				return requestParams;
			},
			toNext3() {
				this.swiper1[0].slideTo(0, 1000, false)
			},
			toNext() {
				location.href=this.baseInfo.woyao_url
			},
			toNext2() {
				this.swiper1[1].slideTo(2, 1000, false)
			},
			async loadInfo() {
				const res = await this.http.fetchPost('/api/Content/config')
				this.baseInfo = res.data
				const res2 = await this.http.fetchPost('/api/Content/agree', {
					type: 'ai'
				})
				this.aiInfo = localStorage.getItem('language_key') == 'zh' ? res2.data.content : localStorage.getItem(
					'language_key') == 'en' ? res2.data.en_content : res2.data.tai_content

			},
			async loadData() {
				const res = await this.http.fetchPost('/api/Index/index')
				this.banner = res.data.banner
				this.pc_video = res.data.pc_video
				this.mb_video = res.data.mb_video
				this.sfot = res.data.sfot

			},
			toHose() {
				this.$router.push('houseOne')
			},
			async send() {
				let data = {
					name: this.name,
					mobile: this.mobile,
					yu_time: this.yu_time
				}
				const res = await this.http.fetchPost('/api/Content/make', data)
				if (res.code == 1) {
					this.$message.success(this.$t('room.room20'))
					this.name = ''
					this.mobile = ''
					this.yu_time = ''
				} else {
					this.$message.error(res.msg)
				}
			}
		}
	}
</script>
<style lang="less" scoped>
	div {
		&::-webkit-scrollbar {
			width: 0;
		}

		&::-webkit-scrollbar {
			width: 0;
		}
	}

	.app-info {
		&::-webkit-scrollbar {
			background-color: rgba(255, 255, 255, 0.2);
			width: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0);
			border-radius: 8px;
			background-clip: content-box;
			border: 2px solid transparent;
		}
	}

	@media screen and (max-width: 750px) {
		/deep/.el-date-editor {
			background-color: transparent !important;
		}

		/deep/.el-input__inner {
			background-color: transparent;
			border: none;
			outline: none;
			color: #fff;
			padding-left: 14px !important;
		}

		/deep/.el-input__icon {
			display: none !important;
		}

		.imgItem {
			width: 100vw;
			height: 100vh;
			overflow: hidden;
			position: relative;
			z-index: 2;

			.about {
				width: 100%;
				position: absolute;
				bottom: 0;
				padding: 20px 32px;
				font-size: 12px;
				font-weight: 400;
				color: #FFFFFF;

				.aBox {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					.imgs {
						flex: 1;

						img {
							width: 22px;
							height: 22px;
						}
					}

				}
			}

			.cellMe {
				padding: 32px;
				padding-top: 12vh;

				.Ctitle {
					font-size: 22px;
					font-weight: 600;
					color: #fff;
					margin-bottom: 24px;
				}

				.tInfo {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
				}

				.btn {
					width: 100%;
					height: 38px;
					background: #FFFFFF60;
					margin-top: 44px;
					text-align: center;
					line-height: 38px;
					font-size: 16px;
					color: #fff;
				}

				.inp {
					display: flex;
					align-items: center;
					padding: 10px 0;
					border-bottom: 1px solid #ffffff30;
					margin-bottom: 42px;

					img {
						width: 22px;
						height: 22px;
					}

					input {
						flex: 1;
						font-size: 14px;
						margin-left: 10px;
						background-color: transparent;
						border: none;
						outline: none;
						color: #fff;

					}
				}
			}

			.toMore {
				position: absolute;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				bottom: 80px;
				left: 32px;

				img {
					width: 56px;
					height: 28px;
					margin-top: 34px;
				}
			}

			.btns {
				position: absolute;
				right: 50px;
				bottom: 60px;

				.btn {
					cursor: pointer;
					width: 308px;
					height: 64px;
					background: #00000050;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 20px;
					color: #fff;
					font-size: 22px;

					img {
						width: 32px;
						height: 32px;
						margin-left: 20px;
					}
				}
			}

			.title {
				font-size: 32px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				position: relative;
				margin-top: 20vh;
				margin-left: 32px;
				padding-bottom: 10px;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0px;
					width: 300px;
					height: 1px;
					background: #FFFFFF;
					opacity: 0.3;
				}
			}

			.right {
				width: 40px;
				height: 40px;
				margin-left: 32px;
			}

			.subTitle {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				margin-left: 32px;
				margin-top: 77px;
				color: #FFFFFF;
				padding-bottom: 40px;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0px;
					width: 180px;
					height: 1px;
					background: #FFFFFF;
				}
			}
		}
	}

	@media screen and (min-width: 750px) {
		/deep/.el-date-editor {
			background-color: transparent !important;
		}

		/deep/.el-input__inner {
			background-color: transparent;
			border: none;
			outline: none;
			font-size: 16px;
			color: #fff;
		}

		/deep/.el-input__icon {
			display: none !important;
		}

		@keyframes bounce-down {
			25% {
				transform: translateY(-6px);
			}

			50%,
			100% {
				transform: translateY(0);
			}

			75% {
				transform: translateY(6px);
			}
		}

		@keyframes bounce-down2 {
			25% {
				transform: translateX(-4px);
			}

			50%,
			100% {
				transform: translateX(0);
			}

			75% {
				transform: translateX(4px);
			}
		}

		.callPage {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			transition: 0.8s;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.tit {
				font-size: 43px;
				font-weight: 600;
				color: #FFFFFF;
				margin-bottom: 50px;
			}

			.more {
				cursor: pointer;
				width: 238px;
				height: 46px;
				background: #FFFFFF40;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;

				img {
					width: 24px;
					height: 24px;
					margin-left: 48px;
				}
			}
		}

		.aboutPage {
			width: 100vw;
			height: 100vh;

			position: fixed;
			top: 0;
			left: 0;
			z-index: 1;
			transition: 0.8s;
		}

		.homePage {
			width: 100vw;
			height: 100vh;

			.down {
				width: 48px;
				height: 48px;
				position: absolute;
				bottom: 60px;
				left: 60px;
				cursor: pointer;
				animation: bounce-down 1.6s linear infinite;
				z-index: 10;
			}

			.imgItem {
				width: 100vw;
				height: 100vh;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				position: relative;
				z-index: 2;

				.btns {
					position: absolute;
					right: 50px;
					bottom: 60px;

					.btn {
						cursor: pointer;
						width: 219px;
						height: 46px;
						background: #00000050;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 20px;
						color: #fff;
						font-size: 16px;

						img {
							width: 24px;
							height: 24px;
							margin-left: 20px;
						}
					}
				}

				.title {
					font-size: 32px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
					position: relative;
					margin-top: 144px;
					margin-left: 88px;
					padding-bottom: 14px;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0px;
						width: 300px;
						height: 1px;
						background: #FFFFFF;
						opacity: 0.3;
					}
				}

				.right {
					width: 46px;
					height: 46px;
					margin-left: 88px;
				}

				.subTitle {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					margin-left: 88px;
					margin-bottom: 60px;
					color: #FFFFFF;
					padding-bottom: 14px;
					padding-top: 14px;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0px;
						width: 180px;
						height: 1px;
						background: #FFFFFF;
						opacity: 0.3;
					}
				}
			}
		}

		.indexPc {
			width: 100vw;
			height: 100vh;

			.back {
				width: 100vw;
				height: 100vh;
			}

			.down {
				width: 48px;
				height: 48px;
				position: absolute;
				bottom: 60px;
				left: 60px;
				cursor: pointer;
				z-index: 10;
				animation: bounce-down 1.6s linear infinite;
			}

			.more {
				position: absolute;
				bottom: 102px;
				right: 102px;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				animation: bounce-down2 2s linear infinite;

				.mImg {
					width: 32px;
					height: 32px;
					margin-top: 20px;
				}

				.name {
					width: 22px;
					height: 64px;
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 16px;
				}
			}


		}

		.back {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
		}

		.morePage {
			width: 100vw;
			height: 100vh;
			z-index: 1;
			transition: 0.8s;

			.moreBox {
				position: relative;
				width: 100vw;
				height: 100vh;
				background: rgba(0, 0, 0, 0.7);

				.footer {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					background-color: #000;
					box-sizing: border-box;
					padding: 20px 60px;

					.copy {
						width: 100%;
						margin: 0 auto;
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						text-align: center;
						margin-top: 36px;
					}

					.down {
						display: none;
					}

					.info {
						width: 100%;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;

						.title {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
							margin-bottom: 20px;
						}

						.imgList {
							display: flex;

							.imgs {
								width: 32px;
								height: 32px;
								margin-right: 22px;
							}
						}

						.cell {
							font-size: 14px;
							font-weight: 400;
							color: #FFFFFF;

						}

						.phone {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
							margin: 10px 0;
						}
					}

				}

				.centerBox {
					width: 100%;
					margin: 0 auto;
					height: 100vh;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 102px;

					.title {
						font-size: 34px;
						font-weight: 600;
						color: #FFFFFF;
						margin-bottom: 40px;
					}

					.sub {
						font-size: 16px;
						font-weight: 600;
						color: #FFFFFF;
						line-height: 22px;
						margin-top: 10px;
					}

					.sendBox {
						width: 389px;

						.item {
							display: flex;
							align-items: center;
							padding: 17px 0;
							border-bottom: 1px solid #fff;

							img {
								width: 24px;
								height: 24px;
							}

							input {
								flex: 1;
								font-size: 16px;
								margin-left: 20px;
								background-color: transparent;
								border: none;
								outline: none;
								color: #fff;
							}
						}

						.btn {
							width: 100%;
							height: 56px;
							background: #FFFFFF60;
							margin-top: 44px;
							text-align: center;
							line-height: 56px;
							font-size: 16px;
							color: #fff;
						}
					}
				}

			}


		}
	}

	@media screen and (min-width: 1921px) {
		/deep/.el-date-editor {
			background-color: transparent !important;
		}

		/deep/.el-input__inner {
			background-color: transparent;
			border: none;
			outline: none;
			font-size: 22px;
			color: #fff;
		}

		/deep/.el-input__icon {
			display: none !important;
		}

		@keyframes bounce-down {
			25% {
				transform: translateY(-6px);
			}

			50%,
			100% {
				transform: translateY(0);
			}

			75% {
				transform: translateY(6px);
			}
		}

		@keyframes bounce-down2 {
			25% {
				transform: translateX(-4px);
			}

			50%,
			100% {
				transform: translateX(0);
			}

			75% {
				transform: translateX(4px);
			}
		}

		.callPage {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			transition: 0.8s;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.tit {
				font-size: 60px;
				font-weight: 600;
				color: #FFFFFF;
				margin-bottom: 50px;
			}

			.more {
				cursor: pointer;
				width: 334px;
				height: 66px;
				background: #FFFFFF40;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;

				img {
					width: 32px;
					height: 32px;
					margin-left: 48px;
				}
			}
		}

		.aboutPage {
			width: 100vw;
			height: 100vh;

			position: fixed;
			top: 0;
			left: 0;
			z-index: 1;
			transition: 0.8s;
		}

		.homePage {
			width: 100vw;
			height: 100vh;

			.down {
				width: 48px;
				height: 48px;
				position: absolute;
				bottom: 60px;
				left: 60px;
				cursor: pointer;
				animation: bounce-down 1.6s linear infinite;
				z-index: 10;
			}

			.imgItem {
				width: 100vw;
				height: 100vh;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				position: relative;
				z-index: 2;

				.btns {
					position: absolute;
					right: 50px;
					bottom: 60px;

					.btn {
						cursor: pointer;
						width: 308px;
						height: 64px;
						background: #00000050;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 20px;
						color: #fff;
						font-size: 22px;

						img {
							width: 32px;
							height: 32px;
							margin-left: 20px;
						}
					}
				}

				.title {
					font-size: 60px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
					position: relative;
					margin-top: 194px;
					margin-left: 140px;
					padding-bottom: 40px;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0px;
						width: 300px;
						height: 1px;
						background: #FFFFFF;
						opacity: 0.3;
					}
				}

				.right {
					width: 82px;
					height: 82px;
					margin-left: 140px;
				}

				.subTitle {
					font-size: 32px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					margin-left: 140px;
					margin-bottom: 60px;
					color: #FFFFFF;
					padding-bottom: 40px;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0px;
						width: 180px;
						height: 1px;
						background: #FFFFFF;
					}
				}
			}
		}

		.indexPc {
			width: 100vw;
			height: 100vh;

			.back {
				width: 100vw;
				height: 100vh;
			}

			.down {
				width: 48px;
				height: 48px;
				position: absolute;
				bottom: 60px;
				left: 60px;
				cursor: pointer;
				z-index: 10;
				animation: bounce-down 1.6s linear infinite;
			}

			.more {
				position: absolute;
				bottom: 102px;
				right: 102px;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				animation: bounce-down2 2s linear infinite;

				.mImg {
					width: 32px;
					height: 32px;
					margin-top: 20px;
				}

				.name {
					width: 22px;
					height: 64px;
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 16px;
				}
			}


		}

		.back {
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
		}

		.morePage {
			width: 100vw;
			height: 100vh;
			z-index: 1;
			transition: 0.8s;

			.moreBox {
				position: relative;
				width: 100vw;
				height: 100vh;
				background: rgba(0, 0, 0, 0.7);

				.footer {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					background-color: #000;
					box-sizing: border-box;
					padding: 20px 60px;

					.copy {
						width: 1200px;
						margin: 0 auto;
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						text-align: center;
						margin-top: 36px;
					}

					.down {
						width: 48px;
						height: 48px;
						cursor: pointer;
						animation: bounce-down 1.6s linear infinite;
						position: absolute;
						bottom: 60px;
						left: 60px;
						z-index: 10;
					}

					.info {
						width: 1200px;
						margin: 0 auto;
						display: flex;
						justify-content: space-between;

						.title {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
							margin-bottom: 20px;
						}

						.imgList {
							display: flex;

							.imgs {
								width: 32px;
								height: 32px;
								margin-right: 22px;
							}
						}

						.cell {
							font-size: 14px;
							font-weight: 400;
							color: #FFFFFF;

						}

						.phone {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
							margin: 10px 0;
						}
					}

				}

				.centerBox {
					width: 1200px;
					margin: 0 auto;
					height: 100vh;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.title {
						font-size: 48px;
						font-weight: 600;
						color: #FFFFFF;
						margin-bottom: 40px;
					}

					.sub {
						font-size: 22px;
						font-weight: 600;
						color: #FFFFFF;
						line-height: 22px;
						margin-top: 10px;
					}

					.sendBox {
						width: 540px;

						.item {
							display: flex;
							align-items: center;
							padding: 24px 0;
							border-bottom: 1px solid #fff;

							img {
								width: 32px;
								height: 32px;
							}

							input {
								flex: 1;
								font-size: 22px;
								margin-left: 20px;
								background-color: transparent;
								border: none;
								outline: none;
								color: #fff;
							}
						}

						.btn {
							width: 547px;
							height: 56px;
							background: #FFFFFF60;
							margin-top: 44px;
							text-align: center;
							line-height: 56px;
							font-size: 16px;
							color: #fff;
						}
					}
				}

			}


		}

	}
</style>