<template>
	<div>
		<div class="pagePC">
			<div class="navBar">
				<div class="nLeft">
					<img :src="baseInfo.logo" class="logo" @click.stop="home" />
					<div class="name" @click.stop="toH(item.url)" v-for="item in baseInfo.top_menu">{{lang=='en'?item.en_name:lang=='zh'?item.name:item.tai_name}}</div>
				</div>
				<div class="nRight">
					<div class="u-flex-y-center" style="cursor: pointer;position: relative;"
						@click.stop="showLang=!showLang">
						<img src="../assets/lang.png" style="width: 32px;height: 32px;" />
						<div class="zi">{{lang=='zh'?'中文':lang=='en'?'EN':'ภาษาไทย'}}</div>
						<img src="../assets/down.png" style="width: 16px;height: 16px;" />
						<div style="width: 150px;padding: 20px;background-color: #151515;left: 0;bottom: -180px;position: absolute;"
							v-if="showLang">
							<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('zh')">中文
							</div>
							<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('en')">
								English</div>
							<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('th')">
								ภาษาไทย</div>
						</div>
					</div>
					<div class="loginInfo" v-if="isLogin" @click.stop="tabIndex=2,showTop=!showTop">
						<img :src="userInfo.avatar" class="head" />
						<div class="subTitle">{{userInfo.email}}</div>
						<img src="../assets/out.png" class="out" @click.stop="outLogin" />
					</div>
					<div class="name" v-else>
						<span @click.stop="tabIndex=0,showTop=true">{{$t('room.room56')}}</span><span
							style="margin: 0 10px;color:#6A6A6A;">{{$t('room.room57')}}</span><span
							@click.stop="tabIndex=1,showTop=true">{{$t('room.room58')}}</span><span
							style="margin-left: 20px;" @click.stop="showTop=false"
							v-if="showTop==true">{{$t('room.room59')}}</span>
					</div>
				</div>
			</div>
			<div class="warp" v-if="showTop||showLang" @click="showTop=false,showLang=false"></div>
			<div class="rightBoxNav" v-if="tabIndex==0&&showTop">
				<div class="titleB">{{$t('room.room56')}}</div>
				<div class="inp">
					<img src="../assets/yx.png" />
					<input :placeholder="$t('room.room50')" v-model="login.email" />
				</div>
				<div class="inp">
					<img src="../assets/ma.png" />
					<input :placeholder="$t('room.room60')" type="password" v-model="login.password" />
				</div>
				<div class="sureBtn" @click.stop="sendLogin">{{$t('room.room56')}}</div>
				<div class="sureBtn" @click.stop="sendGooGle"><img src="../assets/Google.png" />Google {{$t('room.room56')}}</div>
				<div class="sureBtn" style="background: #ffffff20;" @click.stop="tabIndex=1">{{$t('room.room58')}}</div>
			</div>
			<div class="rightBoxNav" v-if="tabIndex==1&&showTop">
				<div class="titleB">{{$t('room.room58')}}</div>
				<div class="inp">
					<img src="../assets/yx.png" />
					<input :placeholder="$t('room.room50')" v-model="reiger.email" />
				</div>
				<div class="inp">
					<img src="../assets/yzm.png" />
					<input :placeholder="$t('room.room61')" v-model="reiger.smscode" />
					<div class="sureBtn" style="width: 128px;margin: 0;" @click.stop="sendCode">{{$t('room.room62')}}
					</div>
				</div>
				<div class="inp">
					<img src="../assets/sj.png" />
					<input :placeholder="$t('room.room63')" v-model="reiger.mobile" />
				</div>
				<div class="inp">
					<img src="../assets/ma.png" />
					<input :placeholder="$t('room.room60')" type="password" v-model="reiger.password" />
				</div>
				<div class="xy">
					<img src="../assets/checked.png" v-if="checked" @click.stop="check" /><img src="../assets/check.png"
						v-else @click.stop="check" /><span style="margin-left: 10px;">{{$t('room.room64')}}</span>
				</div>
				<div class="sureBtn" @click.stop="sendZhuce">{{$t('room.room58')}}</div>
				<div class="sureBtn"@click.stop="sendGooGle"><img src="../assets/Google.png" />Google {{$t('room.room58')}}</div>
				<div class="tips" @click.stop="tabIndex=0">{{$t('room.room65')}}</div>
			</div>
			<div class="rightBoxNav" v-if="tabIndex==2&&showTop">
				<div class="headBox">
					<img :src="userInfo.avatar" class="head" />
					<div class="hang" @click.stop="tabIndex=3">
						<span>{{userInfo.email}}</span>
						<!-- <img src="../assets/bj.png"/> -->
					</div>
					<div class="bTit">{{$t('room.room66')}}</div>
					<div class="anList">
						<div class="anItem" v-for="(item,index) in list">
							<div class="name">{{item.name}}</div>
							<div class="imgs">
								<img src="../assets/del.png" @click.stop="del(item.id)" />
								<img src="../assets/jr.png" @click.stop="toDetail(item.id)" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightBoxNav" v-if="tabIndex==3&&showTop">

				<div class="infos" style="margin-top: 134px;">
					<div class="name">{{$t('room.room79')}}</div>
					<img :src="userInfo.avatar" />
				</div>
				<div class="infos">
					<div class="name">Line ID</div>
					<div class="nameT">{{userInfo.nickname?userInfo.nickname:'-'}}</div>
				</div>
				<div class="infos">
					<div class="name">{{$t('room.room80')}}</div>
					<div class="nameT">{{userInfo.email}}</div>
				</div>
				<div class="infos">
					<div class="name">{{$t('room.room81')}}</div>
					<div class="nameT">{{userInfo.mobile}}</div>
				</div>

			</div>
		</div>
		<div class="app-obtick" v-if="showT">
			<div class="app-tips">
				<div class="app-tit">
					{{$t('room.room82')}}
				</div>
				<div class="app-info">
					{{$t('room.room85')}}
				</div>
				<div class="app-btns">
					<div class="aBtn1" @click.stop="dels">{{$t('room.room29')}}</div>
					<div class="aBtn1" style="background-color: #666666;" @click.stop="showT=false">
						{{$t('room.room84')}}</div>
				</div>
			</div>
		</div>
		<div class="pageWAP">
			<div class="navBar">
				<div class="logo" @click.stop="home">
					<img :src="baseInfo.logo" />
					<div class="name">99living</div>
				</div>
				<div style="display: flex;">
					<img src="../assets/lang.png" style="width: 22px;height: 22px;margin-right: 20px;"
						@click.stop="showLang=!showLang" />
					<img src="../assets/cdw.png" style="width: 22px;height: 22px;" @click.stop="showWap1=!showWap1" />
				</div>
			</div>
			<div class="warp" v-if="showWap1||showLang" @click="showWap1=false,showLang=false">

				<div class="navList" style="width: 150px;" v-if="showLang">
					<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('zh')">中文</div>
					<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('en')">English
					</div>
					<div style="text-align: center;color: #fff;padding: 10px;" @click.stop="changeLang('th')">ภาษาไทย
					</div>
				</div>
				<div class="navList" v-if="showWap1">
					<div class="navItem" @click.stop="toH(item.url)" v-for="(item,index) in baseInfo.top_menu">
						{{lang=='en'?item.en_name:lang=='zh'?item.name:item.tai_name}}
					</div>
					
					<div class="navItem" v-if="isLogin" @click.stop="tabIndex=2,showTop=true,showWap1=false">
						{{userInfo.email}}
					</div>
					<div class="navItem" v-else>
						<span @click.stop="tabIndex=0,showTop=true,showWap1=false">{{$t('room.room56')}}</span><span
							style="margin: 0 8px;color:#6A6A6A;">{{$t('room.room57')}}</span><span
							@click.stop="tabIndex=1,showTop=true,showWap1=false">{{$t('room.room58')}}</span>
					</div>
				</div>
			</div>
			<div class="app-obtick" v-if="tabIndex==0&&showTop">
				<div class="app-Card">
					<img class="close" src="../assets/close.png" @click.stop="showTop=false" />
					<div class="app-tit">{{$t('room.room56')}}</div>
					<div class="inp">
						<img src="../assets/yx.png" />
						<input :placeholder="$t('room.room50')" v-model="login.email" />
					</div>
					<div class="inp">
						<img src="../assets/ma.png" />
						<input :placeholder="$t('room.room60')" type="password" v-model="login.password" />
					</div>
					<div class="sureBtn" @click.stop="sendLogin">{{$t('room.room56')}}</div>
					<div class="sureBtn" @click.stop="sendGooGle"><img src="../assets/Google.png" />Google {{$t('room.room56')}}</div>
					<div class="sureBtn" style="background: #ffffff20;" @click.stop="tabIndex=1">{{$t('room.room58')}}
					</div>
				</div>
			</div>
			<div class="app-obtick" v-if="tabIndex==1&&showTop">
				<div class="app-Card">
					<img class="close" src="../assets/close.png" @click.stop="showTop=false" />
					<div class="app-tit">{{$t('room.room58')}}</div>
					<div class="inp">
						<img src="../assets/yx.png" />
						<input :placeholder="$t('room.room50')" v-model="reiger.email" />
					</div>
					<div class="inp">
						<img src="../assets/yzm.png" />
						<input :placeholder="$t('room.room61')" v-model="reiger.smscode" />
						<div class="sureBtn" style="width: 128px;margin: 0;" @click.stop="sendCode">
							{{$t('room.room62')}}
						</div>
					</div>
					<div class="inp">
						<img src="../assets/sj.png" />
						<input :placeholder="$t('room.room63')" v-model="reiger.mobile" />
					</div>
					<div class="inp">
						<img src="../assets/ma.png" />
						<input :placeholder="$t('room.room60')" type="password" v-model="reiger.password" />
					</div>
					<div class="xy">
						<img src="../assets/checked.png" v-if="checked" @click.stop="check" /><img
							src="../assets/check.png" v-else @click.stop="check" /><span
							style="margin-left: 10px;">{{$t('room.room64')}}</span>
					</div>
					<div class="sureBtn" @click.stop="sendZhuce">{{$t('room.room58')}}</div>
					<div class="sureBtn" @click.stop="sendGooGle"><img src="../assets/Google.png" />Google {{$t('room.room58')}}</div>
					<div class="tips" @click.stop="tabIndex=0">{{$t('room.room65')}}</div>
				</div>
			</div>
			<div class="app-obtick" v-if="tabIndex==2&&showTop">
				<div class="app-Card">
					<img class="close" src="../assets/close.png" @click.stop="showTop=false" />
					<div class="u-flex-y-center u-flex-between" style="width: 100%;margin-top: 30px;"
						@click.stop="tabIndex=3">
						<div class="u-flex-y-center">
							<img :src="userInfo.avatar" class="head" />
							<div class="hang" style="margin-left: 10px;">
								<span>{{userInfo.email}}</span>

							</div>
						</div>
						<img src="../assets/out.png" class="out" @click.stop="outLogin" />
					</div>
					<div class="anList" style="margin-top: 30px;">
						<div class="anItem" v-for="(item,index) in list">
							<div class="name">{{item.name}}</div>
							<div class="imgs">
								<img src="../assets/del.png" @click.stop="del(item.id)" />
								<img src="../assets/jr.png" @click.stop="toDetail(item.id)" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-obtick" v-if="tabIndex==3&&showTop">
				<div class="app-Card">
					<img class="close" src="../assets/close.png" @click.stop="showTop=false" />
					<div class="infos" style="margin-top: 20px;">
						<div class="name">{{$t('room.room79')}}</div>
						<img :src="userInfo.avatar" />
					</div>
					<div class="infos">
						<div class="name">Line ID</div>
						<div class="nameT">{{userInfo.nickname?userInfo.nickname:'-'}}</div>
					</div>
					<div class="infos">
						<div class="name">{{$t('room.room80')}}</div>
						<div class="nameT">{{userInfo.email}}</div>
					</div>
					<div class="infos">
						<div class="name">{{$t('room.room81')}}</div>
						<div class="nameT">{{userInfo.mobile}}</div>
					</div>
				</div>
			</div>
			<div class="app-obtick" style="align-items: center;" v-if="showT">
				<div class="app-tips">
					<div class="app-tit">
						{{$t('room.room82')}}
					</div>
					<div class="app-info">
						{{$t('room.room85')}}
					</div>
					<div class="app-btns">
						<div class="aBtn1" @click.stop="dels">{{$t('room.room29')}}</div>
						<div class="aBtn1" style="background-color: #666666;" @click.stop="showT=false">
							{{$t('room.room84')}}</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'navBar',
		data() {
			return {
				tabIndex: 0,
				checked: false,
				login: {
					email: '',
					password: ''
				},
				reiger: {
					email: '',
					smscode: '',
					mobile: '',
					password: ''
				},
				showT: false,
				showTop: false,
				isLogin: false,
				userInfo: '',
				baseInfo: '',
				showWap1: false,
				showLang: false,
				lang: localStorage.getItem('language_key') ? localStorage.getItem('language_key') : 'en',
				
				id: '',
				delId: ''
			}
		},
		async created() {
			let params = this.getRequestParams();
			if (params.token) {
				localStorage.setItem('token', params.token)
				const res = await this.http.fetchPost('/api/User/index')
				localStorage.setItem('userInfo', JSON.stringify(res.data))
				this.userInfo = res.data
				this.isLogin = true
				this.loadData()
			} else {
			
			
			}
			if (localStorage.getItem('userInfo')) {
				this.isLogin = true
				this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
				this.tabIndex = 2
				this.loadData()
			}
			this.loadInfo()
			this.$forceUpdate()
		},
		
		methods: {
			getRequestParams() {
				let url = location.href;
				let requestParams = {};
				if (url.indexOf('?') !== -1) {
					let str = url.substr(url.indexOf('?') + 1); //截取?后面的内容作为字符串
					let strs = str.split('&'); //将字符串内容以&分隔为一个数组
					for (let i = 0; i < strs.length; i++) {
						requestParams[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
						// 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
					}
				}
				return requestParams;
			},
			async sendGooGle(){
				const res = await this.http.fetchPost('/api/Login/google')
				location.href=res.data.$url

			},
			toLogin() {
				this.tabIndex = 0
				this.showTop = true
			},
			changeLang(lang) {
				this.lang = lang
				localStorage.setItem('language_key', this.lang)
				this.$i18n.locale = this.lang
				location.reload()
			},
			toH(url) {
				this.showWap1 = false
				location.href = url
			},
			toH2() {
				this.showWap1 = false
				location.href = 'http://99home.co'
			},
			toDetail(id) {
				var that = this
				this.$router.replace({
					path: "/inventory",
					query: {
						"id": id
					}
				}, () => {
					location.reload()
				})
			},
			home() {
				if (this.$route.name == 'home') {
					return
				}

				this.$router.push('/')
			},
			async loadInfo() {
				const res = await this.http.fetchPost('/api/Content/config')
				this.baseInfo = res.data
			},
			outLogin() {
				localStorage.removeItem('token')
				localStorage.removeItem('userInfo')
				this.isLogin = false
				this.tabIndex = 0
				if (this.$route.name == 'home') {
					this.$router.push({ query: {} })
					return
				}
				this.$router.push('/')
			},
			check() {
				console.log(this.checked)
				this.checked = !this.checked
				this.$forceUpdate()
			},
			async dels() {
				await this.http.fetchPost('/api/User/planDeletet', {
					id: this.delId
				})
				this.showT = false
				this.loadData()
			},
			del(id) {
				this.delId = id
				this.showT = true
			},
			async loadData() {
				const res = await this.http.fetchPost('/api/User/planList')
				this.list = res.data
				this.$forceUpdate()
			},
			async sendLogin() {
				const res = await this.http.fetchPost('/api/Login/login', this.login)
				if (res.code == 1) {
					this.$message.success(this.$t('room.room68'))
					localStorage.setItem('userInfo', JSON.stringify(res.data))
					localStorage.setItem('token', res.data.token)
					this.userInfo = res.data
					this.isLogin = true
					this.showTop = false
					setTimeout(()=>{
						location.reload()
					},500)
				} else {
					this.$message.error(res.msg)
				}

			},
			async sendCode() {
				const res = await this.http.fetchPost('/api/Login/sendCode', {
					email: this.reiger.email
				})
				if (res.code == 1) {
					this.$message.success(this.$t('room.room54'))
				} else {
					this.$message.error(this.$t('room.room69'))
				}
			},
			async sendZhuce() {
				if (this.checked) {
					const res = await this.http.fetchPost('/api/Login/register', this.reiger)
					if (res.code == 1) {
						this.$message.success(this.$t('room.room70'))
						localStorage.setItem('userInfo', JSON.stringify(res.data))
						localStorage.setItem('token', res.data.token)
						this.userInfo = res.data
						this.isLogin = true
						this.showTop = false
						this.loadData()
					} else {
						this.$message.error(res.msg ? res.msg : this.$t('room.room71'))
					}
				} else {
					this.$message.error(this.$t('room.room86'))
				}

			}
		}
	}
</script>

<style lang="less" scoped>
	@media screen and (min-width:750px) {
		.warp {
			width: 100vw;
			height: 100vh;
			position: absolute;
			z-index: 2;
		}
		
		.rightBoxNav {
			width: 390px;
			height: 100vh;
			background: #1E1E1E;
			padding: 0 29px;
			box-sizing: border-box;
			overflow-y: auto;
			position: fixed;
			z-index: 19;
			right: 0;
			top: 0;
		
			.infos {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 23px 0;
				border-bottom: 1px solid #3D3D3D;
		
				.name {
					font-size: 16px;
					color: #fff;
				}
		
				img {
					width: 64px;
					height: 64px;
					border-radius: 50%;
				}
		
				.nameT {
					font-size: 22px;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		
			.xy {
				display: flex;
				align-items: center;
				color: #4c4c4c;
				font-size: 14px;
				margin-top: 40px;
				margin-bottom: 80px;
		
				img {
					width: 16px;
					height: 16px;
				}
			}
		
			.headBox {
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-top: 134px;
		
				.head {
					width: 98px;
					height: 98px;
					border-radius: 50%;
					margin-bottom: 20px;
				}
		
				.hang {
					cursor: pointer;
					display: flex;
					align-items: center;
					font-size: 30px;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 30px;
					margin-bottom: 70px;
		
					img {
						width: 32px;
						height: 32px;
						margin-left: 10px;
					}
				}
		
				.bTit {
					font-size: 22px;
					color: #4c4c4c;
					margin-bottom: 40px;
					text-align: left;
					width: 100%;
					cursor: pointer;
				}
		
				.anList {
					width: 100%;
					height: 55vh;
					overflow-y: auto;
		
					&::-webkit-scrollbar {
						width: 0;
					}
		
					&::-webkit-scrollbar {
						width: 0;
					}
		
					.anItem {
						width: 100%;
						height: 88px;
						background: #424242;
						padding: 28px 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						margin-bottom: 20px;
		
						.name {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
						}
		
						.imgs {
							display: flex;
		
							img {
								width: 32px;
								height: 32px;
								margin-left: 20px;
								cursor: pointer;
							}
						}
					}
				}
			}
		
			.tips {
				margin-top: 60px;
				padding-bottom: 60px;
				font-size: 14px;
				text-align: center;
				cursor: pointer;
				font-weight: 400;
				color: #FFFFFF;
			}
		
			.titleB {
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				margin-top: 100px;
				margin-bottom: 60px;
			}
		
			.inp {
				width: 100%;
				border-bottom: 1px solid #4c4c4c;
				padding: 17px 0;
				display: flex;
				align-items: center;
		
				img {
					width: 24px;
					height: 24px;
				}
		
				input {
					padding: 0 20px;
					border: none;
					outline: none;
					font-size: 16px;
					color: #fff;
					background-color: #1E1E1E;
					flex: 1;
					width: 100%;
				}
			}
		
			.sureBtn {
				margin-top: 20px;
				width: 100%;
				cursor: pointer;
				height: 34px;
				background: #FFFFFF40;
				text-align: center;
				line-height: 34px;
				font-size: 12px;
				font-weight: 400;
				color: #FFFFFF;
				z-index: 10;
				display: flex;
				align-items: center;
				justify-content: center;
		
				img {
					width: 32px;
					height: 32px;
					margin-right: 20px;
				}
			}
		
		
		
			.nav {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				font-weight: 400;
				color: #FFFFFF;
				padding: 20px 0;
				border-bottom: 1px solid #4C4C4C;
		
				img {
					width: 32px;
					height: 32px;
				}
			}
		
			.title {
				font-size: 14px;
				font-weight: 600;
				color: #FFFFFF;
				margin-bottom: 20px;
			}
		
			.subTitle {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 20px;
			}
		
		
		
			.line {
				margin: 40px 0;
				width: 282px;
				height: 1px;
				background: #FFFFFF;
			}
		
		
		
		
		
		
		}
		
		.nLeft {
			display: flex;
			align-items: center;
		
			.logo {
				width: 76px;
				height: 32px;
				object-fit: contain;
			}
		
			.name {
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 60px;
			}
		}
		
		.nRight {
			display: flex;
			align-items: center;
			position: relative;
			z-index: 20;
		
			.zi {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				margin: 0 10px;
		
			}
		
			.name {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 60px;
				cursor: pointer;
			}
		
			.loginInfo {
				cursor: pointer;
				display: flex;
				align-items: center;
				margin-left: 60px;
		
				.head {
					width: 56px;
					height: 56px;
					border-radius: 50%;
					margin-right: 20px;
				}
		
				.subTitle {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					margin-right: 48px;
				}
		
				.out {
					cursor: pointer;
					width: 32px;
					height: 32px;
				}
			}
		}
		
		.navBar {
			width: 100%;
			box-sizing: border-box;
			height: 64px;
			padding: 0 30px;
			background: #000000;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 20;
		
		
		}
	}

	@media screen and (max-width: 750px) {
		.warp {
			width: 100vw;
			height: 100vh;
			position: absolute;
			z-index: 2;
		}

		.out {
			cursor: pointer;
			width: 32px;
			height: 32px;
		}

		.app-Card {
			.infos {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 16px 0;
				border-bottom: 1px solid #3D3D3D;

				.name {
					font-size: 14px;
					color: #fff;
				}

				img {
					width: 64px;
					height: 64px;
					border-radius: 50%;
				}

				.nameT {
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.anList {
				width: 100%;
				height: 40vh;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 0;
				}

				&::-webkit-scrollbar {
					width: 0;
				}

				.anItem {
					width: 100%;
					height: 50px;
					background: #424242;
					padding: 16px 26px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					box-sizing: border-box;
					margin-bottom: 10px;

					.name {
						font-size: 18px;
						font-weight: 600;
						color: #FFFFFF;
					}

					.imgs {
						display: flex;

						img {
							width: 22px;
							height: 22px;
							margin-left: 30px;
							cursor: pointer;
						}
					}
				}
			}

			.head {
				width: 52px;
				height: 52px;
				border-radius: 50%;

			}

			.hang {
				display: flex;
				font-size: 14px;
				font-weight: 500;
				color: #FFFFFF;

			}

			.sureBtn {
				margin-top: 30px;
				width: 100%;
				cursor: pointer;
				height: 38px;
				background: #FFFFFF40;
				text-align: center;
				line-height: 38px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				z-index: 10;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 22px;
					height: 22px;
					margin-right: 20px;
				}
			}

			.xy {
				display: flex;
				align-items: center;
				color: #4c4c4c;
				font-size: 14px;
				margin-top: 17px;
				margin-bottom: 40px;

				img {
					width: 16px;
					height: 16px;
				}
			}

			.tips {
				margin-top: 60px;
				font-size: 14px;
				text-align: center;
				cursor: pointer;
				font-weight: 400;
				color: #FFFFFF;
			}

		}

		.navList {
			position: fixed;
			right: 0;
			top: 46px;
			width: 230px;
			background: #222222;
			padding: 20px 32px;

			.navItem {
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				width: 100%;
				padding: 10px 0;
				border-bottom: 1px solid #4c4c4c;

				&:last-child {
					border: none;
				}
			}
		}

		.logo {
			display: flex;
			align-items: center;

			img {
				width: 24px;
				height: 18px;
				object-fit: contain;
				margin-right: 16px;
			}

			.name {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
			}
		}

		.navBar {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 20;
			width: 100%;
			height: 46px;
			background-color: #000000;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 32px;


		}
	}

	@media screen and (min-width: 1921px) {
		.warp {
			width: 100vw;
			height: 100vh;
			position: absolute;
			z-index: 2;
		}

		.rightBoxNav {
			width: 490px;
			height: 100vh;
			background: #1E1E1E;
			padding: 0 40px;
			box-sizing: border-box;
			overflow-y: auto;
			position: fixed;
			z-index: 19;
			right: 0;
			top: 0;

			.infos {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 23px 0;
				border-bottom: 1px solid #3D3D3D;

				.name {
					font-size: 16px;
					color: #fff;
				}

				img {
					width: 64px;
					height: 64px;
					border-radius: 50%;
				}

				.nameT {
					font-size: 22px;
					font-weight: 400;
					color: #FFFFFF;
				}
			}

			.xy {
				display: flex;
				align-items: center;
				color: #4c4c4c;
				font-size: 14px;
				margin-top: 40px;
				margin-bottom: 80px;

				img {
					width: 16px;
					height: 16px;
				}
			}

			.headBox {
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-top: 134px;

				.head {
					width: 98px;
					height: 98px;
					border-radius: 50%;
					margin-bottom: 20px;
				}

				.hang {
					cursor: pointer;
					display: flex;
					align-items: center;
					font-size: 30px;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 30px;
					margin-bottom: 70px;

					img {
						width: 32px;
						height: 32px;
						margin-left: 10px;
					}
				}

				.bTit {
					font-size: 22px;
					color: #4c4c4c;
					margin-bottom: 40px;
					text-align: left;
					width: 100%;
					cursor: pointer;
				}

				.anList {
					width: 100%;
					height: 55vh;
					overflow-y: auto;

					&::-webkit-scrollbar {
						width: 0;
					}

					&::-webkit-scrollbar {
						width: 0;
					}

					.anItem {
						width: 410px;
						height: 88px;
						background: #424242;
						padding: 28px 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						margin-bottom: 20px;

						.name {
							font-size: 22px;
							font-weight: 600;
							color: #FFFFFF;
						}

						.imgs {
							display: flex;

							img {
								width: 32px;
								height: 32px;
								margin-left: 20px;
								cursor: pointer;
							}
						}
					}
				}
			}

			.tips {
				margin-top: 90px;
				font-size: 14px;
				text-align: center;
				cursor: pointer;
				font-weight: 400;
				color: #FFFFFF;
			}

			.titleB {
				font-size: 24px;
				font-weight: 600;
				color: #FFFFFF;
				margin-top: 184px;
				margin-bottom: 80px;
			}

			.inp {
				width: 410px;
				border-bottom: 1px solid #4c4c4c;
				padding: 24px 0;
				display: flex;
				align-items: center;

				img {
					width: 32px;
					height: 32px;
				}

				input {
					padding: 0 20px;
					border: none;
					outline: none;
					font-size: 22px;
					color: #fff;
					background-color: #1E1E1E;
					flex: 1;
					width: 100%;
				}
			}

			.sureBtn {
				margin-top: 30px;
				width: 410px;
				cursor: pointer;
				height: 48px;
				background: #FFFFFF40;
				text-align: center;
				line-height: 48px;
				font-size: 16px;
				font-weight: 400;
				color: #FFFFFF;
				z-index: 10;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 32px;
					height: 32px;
					margin-right: 20px;
				}
			}



			.nav {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				font-weight: 400;
				color: #FFFFFF;
				padding: 20px 0;
				border-bottom: 1px solid #4C4C4C;

				img {
					width: 32px;
					height: 32px;
				}
			}

			.title {
				font-size: 14px;
				font-weight: 600;
				color: #FFFFFF;
				margin-bottom: 20px;
			}

			.subTitle {
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 20px;
			}



			.line {
				margin: 40px 0;
				width: 282px;
				height: 1px;
				background: #FFFFFF;
			}






		}

		.nLeft {
			display: flex;
			align-items: center;

			.logo {
				width: 76px;
				height: 32px;
				object-fit: contain;
			}

			.name {
				cursor: pointer;
				font-size: 22px;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 60px;
			}
		}

		.nRight {
			display: flex;
			align-items: center;
			position: relative;
			z-index: 20;

			.zi {
				font-size: 22px;
				font-weight: 400;
				color: #FFFFFF;
				margin: 0 10px;

			}

			.name {
				font-size: 22px;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 60px;
				cursor: pointer;
			}

			.loginInfo {
				cursor: pointer;
				display: flex;
				align-items: center;
				margin-left: 60px;

				.head {
					width: 56px;
					height: 56px;
					border-radius: 50%;
					margin-right: 20px;
				}

				.subTitle {
					font-size: 22px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					margin-right: 48px;
				}

				.out {
					cursor: pointer;
					width: 32px;
					height: 32px;
				}
			}
		}

		.navBar {
			width: 100%;
			box-sizing: border-box;
			height: 94px;
			padding: 0 60px;
			background: #000000;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 20;


		}
	}
</style>